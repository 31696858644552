import React, { useState } from "react";
import CalendarDay from "./CalendarDay";
import holiday from "../../Assets/Data/holidays.json";
import "../../Css/Calendar.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const Calendar = () => {
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [currentDay, setCurrentDay] = useState(new Date());

  const changeCurrentDay = (day) => {
    setCurrentDay(new Date(day.year, day.month, day.number));
  };

  const handlePrevMonth = () => {
    setCurrentDay(
      (prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDay(
      (prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1)
    );
  };

  const holidays = holiday.filter((event) => event.type === "Holiday");

  return (
    <div className="calendar-wrapper">
      <div className="calendar-body-wrapper">
        <div className="calendar-header">
          <div onClick={handlePrevMonth}>
          <FaAngleLeft className="calendar-prev-button"/>
          </div>
          <div>
            {months[currentDay.getMonth()]} {currentDay.getFullYear()}
          </div>
          <div onClick={handleNextMonth}>
            <FaAngleRight className="calendar-next-button"/>
          </div>
        </div>
        <div>
          <div className="calendar-body">
            <div className="table-header weekday-wrapper">
              {weekdays.map((weekday, index) => (
                <div className="weekday" key={index}>
                  <p>{weekday}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <CalendarDay
              currentDay={currentDay}
              changeCurrentDay={changeCurrentDay}
              holidays={holidays}
            />
          </div>
        </div>
        <div className="calendar-event">
          {holidays
            .filter(
              (holiday) =>
                new Date(holiday.date).toDateString() ===
                currentDay.toDateString()
            )
            .map((holiday) => (
              <p key={holiday.name} className={"holidayName"}>
                {holiday.name}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
