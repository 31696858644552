import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

// Component imports
import Calendar from "./Calendar/Calendar";
import JobOpening from "./JobOpening";
import MobileCalendar from "./Calendar/MobileCalender";

// Css imports
import "../Css/Dashboard.css";
import "swiper/css";
import "swiper/css/pagination";

// Image imports
import youtube from "../Assets/Images/Stats/youtube.svg";

//Data imports
import { EventsData } from "../Assets/Data/EventsJSON";
import AdData from "../Assets/Data/Advertisement.json";

const Dashboard = ({ dashboardRef }) => {
  const swiperRef = useRef();
  const swiperRef2 = useRef();
  const [page, setPage] = useState(0);
  const [eventPage, setEventPage] = useState(0);

  return (
    <div>
      <div className="mobilefor">
        <MobileCalendar />
      </div>
      <div className="StatLiner" ref={dashboardRef}>
      <div className="dash-bg1"></div>
        <div className="StatsContainer">
          <div className="col1">
            <div className="mobile-heading">
              Important <span style={{ color: "#DBFF00" }}>Updates</span>
            </div>
            <Swiper
              pagination={{ clickable: true }}
              // loop={true}
              className="mySwiper pastEventsSwiper"
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              onSlideChange={(swiper) => setPage(swiper.realIndex)}
            >
              {AdData.map((ad, index) => (
                <SwiperSlide key={index}>
                  <div className="stat1">
                    <img src={ad.image} alt="Stat" className="Statimg" />
                    <div className="ImgDescription">
                      <div className="ImgTitle">{ad.title}</div>
                      <div className="ImgDesc">
                        <span className="description">{ad.desc}</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="custom-pagination">
                {AdData.map((_, i) => (
                  <span
                    key={i}
                    className={`dot ${page === i ? "active" : ""}`}
                    onClick={() => swiperRef.current.slideTo(i)}
                  />
                ))}
              </div>
            </Swiper>

            <div
              className="stat2"
              // data-aos="fade-right"
              // data-aos-once="true"
            >
              <JobOpening />
            </div>
          </div>
          <div className="col2">
            <div
              className="GolStatus"
              // data-aos="fade-left"
              // data-aos-once="true"
            >
              <div className="GolStat1">
                {/* <Calendar /> */}
                <Calendar />
              </div>
            </div>

            <div className="mobile-heading">
              Upcoming <span style={{ color: "#DBFF00" }}>Webinars</span>
            </div>
            <div className="swiper-wrapper">
              <Swiper
                pagination={false}
                loop={true}
                modules={[Pagination, Autoplay]}
                className="mySwiper hero-title-head sizer"
                onSwiper={(swiper) => (swiperRef2.current = swiper)}
                onSlideChange={(swiper) => setEventPage(swiper.realIndex)}
              >
                {EventsData.map((event, id) => (
                  <SwiperSlide key={id} className={`webinarSlide`}>
                    <div className="EachSlide">
                      <div className="StatType">{event.statType}</div>
                      <div className="eventContainer">
                        <div className="eventDetailContainer">
                          <div className="StatDetails">{event.statDetails}</div>
                          <div className="StatLink">
                            <a
                              href={event.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={youtube} alt="youtube" />
                            </a>
                          </div>
                        </div>
                        <div className={`StatCardImg ${event.type}`}>
                          <img src={event.img} alt="stat-card" />
                        </div>
                        <div className="webinar-black"></div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="custom-pagination">
                  {EventsData.map((_, i) => (
                    <div
                      key={i}
                      onClick={() => swiperRef2.current.slideTo(i)}
                      style={{ cursor: "pointer" }}
                      className={`dot ${eventPage === i ? "active" : ""}`}
                    >
                      <span />
                    </div>
                  ))}
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
