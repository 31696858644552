import React, { useEffect, useRef, useState } from "react";
import certificateFrame from "../ContactUsPage/Assets/Images/CertificateFrame.png";
import jsPDF from "jspdf";

import "./Css/CertificatePage.css";
import axios from "axios";
import { baseUrl } from "../Url";
import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import html2canvas from "html2canvas";

const CertificateGenerator = () => {
  const { internID } = useParams();
  const certificateRef = useRef();
  const authenicate = false;

  const [certificateData, setCertificateData] = useState({});
  const getCertificateDetails = async () => {
    try {
      //   setLoader(true);
      const res = await axios.get(`${baseUrl}/getCertificate/${internID}`);
      if (res?.data) {
        setCertificateData(res?.data);
        // setLoader(false);
      } else if (!res?.data) {
        // setErrorMsg("Certificate Not Valid !");
        // setLoader(false);
      }
    } catch (error) {
      console.error("Error in fetching data", error);
      //   setErrorMsg("Certificate Not Valid !");
      //   setLoader(false);
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    let daySuffix;

    if (day > 3 && day < 21) daySuffix = "th";
    else daySuffix = ["th", "st", "nd", "rd"][day % 10];

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return {
      day: day,
      suffix: daySuffix,
      month: monthName,
      year: year,
    };
  }
  const downloadImage = () => {
    setTimeout(() => {
      html2canvas(certificateRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.download = "certificate.png";
        link.href = canvas.toDataURL();
        link.click();
      });
    }, 2000); // delay of 2 seconds
  };
  const downloadPdf = () => {
    setTimeout(() => {
      html2canvas(certificateRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdfWidth = 297; 
        const pdfHeight = 210;
  
        // Calculate the dimensions of the image
        const imgWidth = pdfWidth;
        // const imgHeight = imgWidth / aspectRatio;
        const imgHeight = pdfHeight;
  
        const pdf = new jsPDF('l', 'mm', 'a4'); // 'l' for landscape mode
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save("certificate.pdf");
      });
    }, 2000); // delay of 2 seconds
  };
  
  useEffect(() => {
    const preventRightClick = (event) => {
      event.preventDefault();
    };

    const preventInspect = (event) => {
      if (event.keyCode === 123) {
        // F12 key code
        event.preventDefault();
      } else if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
        // Ctrl+Shift+I key code
        event.preventDefault();
      } else if (event.ctrlKey && event.keyCode === 85) {
        // Ctrl+U key code
        event.preventDefault();
      }
    };

    window.addEventListener("contextmenu", preventRightClick);
    window.addEventListener("keydown", preventInspect);

    getCertificateDetails();

    return () => {
      window.removeEventListener("contextmenu", preventRightClick);
      window.removeEventListener("keydown", preventInspect);
    };
     // eslint-disable-next-line
  }, [internID]);
  //   useEffect(() => {
  //     sessionStorage.getItem("token") && setAuthenicate(true);
  //   }, [internID]);
  console.log("authenicate", authenicate);
  return (
    <>
      <div style={{ paddingTop: "5%" }}>
        <div className="d-flex justify-content-end pe-4 gap-4">
          <button className="btn btn-primary" onClick={downloadImage}>
            Download Image
          </button>
          <button className="btn btn-primary" onClick={downloadPdf}>
            Download Pdf
          </button>
        </div>
        {/* <div ref={certificateRef}></div> */}
        <div className="certificate-top">
          <div
            ref={certificateRef}
            style={{
              width: "1160px",
              height: "837px",
              position: "relative",
            }}
          >
            <img
              src={certificateFrame}
              style={{
                width: "100%",
                height: "100%",
              }}
              alt="certificate"
            />
            <div className="certificate-name">{certificateData?.name}</div>
            <div className="certificate-details">
              This is to certify that the candidate, a student of{" "}
              {certificateData?.branch} at {certificateData?.college},
              Bhubaneswar, has successfully completed the internship as a{" "}
              <span className="certificate-span">
                {certificateData?.designation}
              </span>{" "}
              at <span className="certificate-span">Squbix Digital</span> from{" "}
              <span className="certificate-span">
                {formatDate(certificateData?.startDate).day}
                <sup>{formatDate(certificateData?.startDate).suffix}</sup>{" "}
                {formatDate(certificateData?.startDate).month}{" "}
                {formatDate(certificateData?.startDate).year}
              </span>{" "}
              to{" "}
              <span className="certificate-span">
                {formatDate(certificateData?.endDate).day}
                <sup>{formatDate(certificateData?.endDate).suffix}</sup>{" "}
                {formatDate(certificateData?.endDate).month}{" "}
                {formatDate(certificateData?.endDate).year}
              </span>
              .
            </div>
            <div className="issue-date">
              {formatDate(certificateData?.issueDate).day}
              <sup>{formatDate(certificateData?.issueDate).suffix}</sup>{" "}
              {formatDate(certificateData?.issueDate).month}{" "}
              {formatDate(certificateData?.issueDate).year}
            </div>
            <div className="qr-code ">
              <QRCodeSVG value={certificateData.qrCodeValue} size={110} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateGenerator;
