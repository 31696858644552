import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";

//todo Css import
import "./Css/AboutUs.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { PiUsersFourBold } from "react-icons/pi";
import { PiUsersFourFill } from "react-icons/pi";
import { FaCircleCheck } from "react-icons/fa6";
// import spark from "./Assets/Images/Frame 1597880653.gif";
// import sparkStatic from "./Assets/Images/Frame 1597880653.svg";
import Teams from "../HomePage/Components/Teams";
import TeamGallery from "../HomePage/Components/TeamGallery";
import Leaders from "../HomePage/Components/Leaders";
import { Link } from "react-router-dom";
import bg1 from "./Assets/SVG/bg1.svg";
import bg2 from "./Assets/SVG/bg2.svg";
import bg3 from "./Assets/SVG/bg3.svg";
import bgmobile from "./Assets/SVG/bgmobile.svg";

// Helper function to preload images
const preloadImages = (imageArray) => {
  imageArray.forEach((image) => {
    const img = new Image();
    img.src = image.img;
  });
};

const AboutUs = ({ setNav }) => {
  //todo Manage the states for the about Us Component
  //todo ----------------------------------------------

  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [key, setKey] = useState(0);
  const [gifPlayedOnce, setGifPlayedOnce] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  // Check window width when resizing
  useEffect(() => {
    preloadImages(images);
    const handleResize = () => {
      setIsMobile(window.innerWidth < 750);
    };

    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const images = [
    {
      img: bg1,
      text: "Innovative solutions crafted with a visionary approach.",
    },
    {
      img: bg2,
      text: "Seamless execution through advanced development tools.",
    },
    {
      img: bg3,
      text: "Elevate your enterprise with transformative technology.",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setGifPlayedOnce(!gifPlayedOnce);
    }, 900);

    return () => clearTimeout(timer);
  }, []);

  const journeyText =
    "Since 2016, we are revolutionizing the Healthcare and Fintech industries with innovative web and mobile solutions. Our SaaS-based healthcare platform has set new standards for efficiency and patient care through cutting-edge automation and analytics. With a global footprint and a dedicated team, we are committed to driving innovation and excellence in every project.";
  const visionText =
    "Our vision is to be a global leader in innovation and sustainability, transforming industries and improving lives through cutting-edge technology and forward-thinking strategies. We strive to create a world where every community thrives, and every individual has the opportunity to reach their full potential.";

  //? Update the key every time the interval runs
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setKey((prevKey) => prevKey + 1);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  //? Navigating About Us Page with AoS Effects
  useEffect(() => {
    setIsIframeLoaded(false);
    setNav("about");
    Aos.init({
      disable: window.innerWidth < 700,
    });
  }, []);

  const [clr, setClr] = useState(false);
  const colorchange = () => {
    setClr(!clr);
    setGifPlayedOnce(!gifPlayedOnce);
  };

  //todo Return the UI of About Us Component
  //todo ------------------------------------

  return (
    <div style={{ width: "100vw", overflowX: "hidden" }}>
      <div className="about-main-container">
        <div className="about-hero-section">
          <div data-aos="fade-down" className="about-hero-heading">
            <span className="unimg_1">You </span>
            <span className="high">Envision</span>,
            <span className="unimg_2"> We </span>
            <span className="high"> Deliver</span>.
          </div>
          <div className="JoinBtn">
            <Link data-aos="fade-down" to="/contactus" className="Link">
              <div
                className="join"
                onMouseEnter={colorchange}
                onMouseLeave={colorchange}
              >
                {!clr ? (
                  <PiUsersFourBold
                    className={clr ? "joinImg joinImgClr" : "joinImg"}
                  />
                ) : (
                  <PiUsersFourFill
                    className={clr ? "joinImg joinImgClr" : "joinImg"}
                  />
                )}

                <span className={clr ? "joinTxt joinTxtClr" : "joinTxt"}>
                  Join Us
                </span>
              </div>
            </Link>
            {/* <img
              src={gifPlayedOnce ? sparkStatic : spark}
              alt="g"
              className="sparkle"
            /> */}
          </div>
          <div className="image_content">
            <div className="c_img">
              {isMobile ? (
                <>
                  <img src={bgmobile} alt="mk" />
                  <div className="pointsDesc">
                    <div className="points">
                      <FaCircleCheck className="checkMark" />
                      <span className="checkText">
                        Innovative solutions crafted with a visionary approach.
                      </span>
                    </div>
                    <div className="points">
                      <FaCircleCheck className="checkMark" />
                      <span className="checkText">
                        Seamless execution through advanced development tools.
                      </span>
                    </div>
                    <div className="points">
                      <FaCircleCheck className="checkMark" />
                      <span className="checkText">
                        Elevate your enterprise with transformative technology.
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={images[currentImageIndex].img}
                    alt={images[currentImageIndex].text}
                  />
                  <div
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    className="txt"
                    key={`txt-${currentImageIndex}`}
                  >
                    {images[currentImageIndex].text}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="about-bg1"></div>
        </div>

        <div className="about-journey-section">
          <div className="about-journey-top">
            <div data-aos="fade-up" className="about-journey-heading">
              Our Journey
            </div>
          </div>
          <div className="desc_jrny_sec">
            <div data-aos="fade-left" className="journey_desc">
              {journeyText}
            </div>
            <div className="jrny_img_comp">
              <div data-aos="fade-right" className="glass_img_comp">
                <img
                  src="https://ik.imagekit.io/dzzp3vpnx/Others/pexels-olly-3754686.jpg?updatedAt=1725358223741"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="about-bg1"></div>
        </div>
        <div className="about-journey-section">
          <div className="about-journey-top">
            <div data-aos="fade-up" className="about-journey-heading">
              Our Vision
            </div>
          </div>
          <div className="desc_jrny_sec">
            <div className="jrny_img_comp1">
              <div data-aos="fade-left" className="glass_img_comp1">
                <img
                  src="https://ik.imagekit.io/dzzp3vpnx/Others/pexels-burakkostak-18809.jpg?updatedAt=1725358498649"
                  alt="img"
                />
              </div>
            </div>
            <div data-aos="fade-right" className="journey_desc">
              {visionText}
            </div>
          </div>
          <div className="journey-bg1"></div>
        </div>
        <div className="our-strength">
          <div data-aos="fade-up" className="our-strength-heading">
            Meet the Leadership Team
          </div>
          <div data-aos="fade-up" className="our-strength-content">
            Top industry with proven experience who are hands-on in managing
            projects.
          </div>
        </div>
        <div className="Leaders_Component">
          <Leaders />
        </div>
        <div className="our-strength">
          <div data-aos="fade-up" className="our-strength-heading">
            Our Strength
          </div>
          <div data-aos="fade-up" className="our-strength-content">
            Fueled by a sense of purpose, our greatest asset, our people, thrive
            through continuous development.
          </div>
        </div>

        <div className="about-team">
          <Teams />
        </div>

        <div className="our-culture">
          <div data-aos="fade-up" className="our-culture-heading">
            Our Culture
          </div>
          <div data-aos="fade-up" className="our-culture-content">
            We believe in creating a work environment that fosters creativity
            and innovation.
          </div>
        </div>

        <div className="about-team-gallery">
          <div className="dark-left-team-container"></div>
          <TeamGallery />
          <div className="dark-right-team-container"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
