import React, { useEffect } from "react";
import "../Css/Language.css";
import aws from "../Assets/IMG/Languages/AWS.png";
import Docker from "../Assets/IMG/Languages/Docker.png";
import firebase from "../Assets/IMG/Languages/Firebase.png";
import next from "../Assets/IMG/Languages/Nextjs.png";
import react from "../Assets/IMG/Languages/React.png";
import rust from "../Assets/IMG/Languages/Rust.png";
import ethereum from "../Assets/IMG/Languages/ethereum.png";
import kafka from "../Assets/IMG/Languages/kafka.png";
import node from "../Assets/IMG/Languages/node.png";
import graphql from "../Assets/IMG/Languages/graphql.png";
import kubernetes from "../Assets/IMG/Languages/kubernetes.png";
import mongodb from "../Assets/IMG/Languages/mongodb.png";
import reactnative from "../Assets/IMG/Languages/reactnative.png";

const Language = () => {
  const Language = [
    {
      id: "1",
      img: aws,
    },
    {
      id: "2",
      img: Docker,
    },
    {
      id: "3",
      img: firebase,
    },
    {
      id: "4",
      img: next,
    },
    {
      id: "5",
      img: react,
    },
    {
      id: "6",
      img: ethereum,
    },
    {
      id: "7",
      img: kafka,
    },
    {
      id: "8",
      img: node,
    },
    {
      id: "9",
      img: rust,
    },
    {
      id: "10",
      img: graphql,
    },
    {
      id: "11",
      img: kubernetes,
    },
    {
      id: "12",
      img: mongodb,
    },
    {
      id: "13",
      img: reactnative,
    },
  ];

  const allImages = [...Language, ...Language, ...Language];

  useEffect(() => {
    var windowWidth = window.innerWidth;
    var translateXValue = 7020 - windowWidth ;
    var element = document.querySelector(".car-image-wrapper");

    var calculateDuration = function (width) {
      return 50000 / (width / 3000);
    };

    var keyframes = [
      { transform: "translateX(0)" },
      { transform: "translateX(" + -translateXValue + "px)" },
    ];

    var options = {
      duration: calculateDuration(windowWidth),
      iterations: Infinity,
    };
    element.animate(keyframes, options);
  }, []);

  return (
    <div className="carousel-pages">
      <div className="car-image-wrapper">
        {allImages.map((image, index) => (
          <div key={index} className="lang-image">
            <img src={image.img} alt={`team-gallery-${index}`} loading="lazy" />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Language;
