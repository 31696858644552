import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import "../../Css/Products.css";
import { EffectCoverflow } from "swiper/modules";
import { useNavigate } from "react-router-dom";

import { data } from "../../Assets/Data/ProductDetailsJSON";

const Products = () => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const handleClick = (id) => {
    console.log(id);
    navigate("/products/" + id);
  };
  return (
    <div style={{ display: "inline" }}>
      <div className="prod-heading">{data[page].name}</div>
      <div className="product-container">
        <Swiper
          onClick={() => handleClick(data[page].link)}
          spaceBetween={30}
          effect={"coverflow"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          modules={[EffectCoverflow]}
          className="mySwiper prodSwiper"
          ref={swiperRef}
          loop
          speed={800}
          touchMoveStopPropagation={false}
          onSlideChange={(swiper) => setPage(swiper.realIndex)}
        >
          {data.map((prod, index) => (
            <SwiperSlide
              className={`prod-container ${prod.type + "-container"}`}
              key={index}
            >
              <img
                src={prod.images[0]}
                alt={`Product ${index + 1}`}
                className={`${data[index].type}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="custom-pagination" style={{ bottom: "-42px" }}>
          {data.map((_, i) => (
            <span
              key={i}
              className={`dot ${page === i ? "active" : ""}`}
              onClick={() =>
                swiperRef.current && swiperRef.current.swiper.slideTo(i)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
