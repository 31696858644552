export const EventsData = [
  {
    "id": 0,
    "statType": "Discussion",
    "statDetails": "Project Review Meeting & Retrospective",
    "url":"https://youtu.be/WWAA5rZfBQ8",
    "img" : "https://ik.imagekit.io/z2g6kc7ex/Squbix/Squbix%20new%20website/member.png?updatedAt=1712749463895",
    "type":"team"
  },
  {
    "id": 1,
    "statType": "Discussion",
    "statDetails": "Discussion on Multi-tenant Platform ",
    "url":"https://youtu.be/LwjTzPxssPI?si=UZmWwfip9qvWW2Vu",
    "img" : "https://ik.imagekit.io/z2g6kc7ex/Squbix/Squbix%20new%20website/deepak.png?updatedAt=1713163841797",
    "type": "single"
  },
]
