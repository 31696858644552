import React, { useState } from "react";
import "../Blog/CSS/blog.css";
import { Link } from "react-router-dom";

const Blogcard = ({ id, img, headlines, technology, date, nav }) => {
  const [hoverID, setHoverID] = useState(-1);
  return (
    <Link
      to={`/blog/${nav}`}
      className="blog-card-link"
      onMouseEnter={() => setHoverID(id)}
      onMouseLeave={() => setHoverID(-1)}
    >
      <div className="blog-img-container">
        <img
          className={`blog-card-img ${id === hoverID && "blog-img-hovered"}`}
          src={img}
          alt="img"
        />
      </div>
      <div className="tech-date-container">
        <div className="blogcard-tech">{technology}</div>
      </div>
      <h2
        className="blogcard-headline"
        style={id === hoverID ? { color: "#dbff00" } : {}}
      >
        {headlines}
      </h2>
    </Link>
  );
};

export default Blogcard;
