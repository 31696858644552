import React, { useEffect, useRef, useState } from "react";
import holiday from "../../Assets/Data/holidays.json";

import next from "../../Assets/Images/Stats/next.png";
import prev from "../../Assets/Images/Stats/prev.png";

import "../../Css/Calendar.css";

const MobileCalendar = () => {
  const [animationDuration, setAnimationDuration] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      const listWidth = listRef.current.offsetWidth;
      const duration = listWidth / 100; // adjust this to change the speed
      setAnimationDuration(duration);
    }
  }, [listRef]);

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [currentDay, setCurrentDay] = useState(new Date());

  const handlePrevMonth = () => {
    setCurrentDay(
      (prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDay(
      (prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1)
    );
  };

  const holidays = holiday.filter((event) => event.type === "Holiday");

  const currentMonthHolidays = holidays.filter(
    (holiday) => new Date(holiday.date).getMonth() === currentDay.getMonth()
  );

  return (
    <div className="calendar-wrapper">
      <div className="calendar-body-wrapper">
        <div className="calendar-header">
          <img
            src={prev}
            alt=""
            onClick={handlePrevMonth}
            className="calendar-prev-button"
          />
          <div>
            {months[currentDay.getMonth()]} {currentDay.getFullYear()}
          </div>
          <img
            src={next}
            alt=""
            onClick={handleNextMonth}
            className="calendar-next-button"
          />
        </div>
        {currentMonthHolidays.length>0 && 
        <ul
          ref={listRef}
          style={{ animationDuration: `${animationDuration + 15}s` }}
          className="mobile-calendar-body"
        >
          {currentMonthHolidays.map((holiday) => {
            const date = new Date(holiday.date);
            return (
              <li key={holiday.name} className="holiday-list">
                <span style={{ opacity: "50%" }}>
                  {date.getDate()} {weekdays[date.getDay()]} -{" "}
                </span>{" "}
                <span style={{ color: "#EF6161" }}>{holiday.name}</span>
              </li>
            );
          })}
        </ul>
        }
      </div>
    </div>
  );
};

export default MobileCalendar;
