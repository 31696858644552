import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../Css/Dashboard.css";

// import applybtn from "../Assets/Images/Stats/applybtn.svg";
import next from "../Assets/Images/Stats/next.png";
import prev from "../Assets/Images/Stats/prev.png";
import AvailableJobs from "../Assets/Data/JobListing.json";
import Loc from "../Assets/Icons/Stats/Location.svg";

function JobOpening() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    afterChange: (current) => {
      setCurrentSlide(current);
      setIsNextDisabled(current === AvailableJobs.length - 1);
      setIsPrevDisabled(current === 0);
    },
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 865,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 478,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 421,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 337,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setIsPrevDisabled(currentSlide === 0);
    let value = 3;
    if (window.innerWidth < 620) {
      value = 1;
    } else if (window.innerWidth < 770) {
      value = 2;
    } else if (window.innerWidth < 865) {
      value = 1;
    } else if (window.innerWidth < 1150) {
      value = 2;
    }
    setIsNextDisabled(currentSlide === AvailableJobs.length - value);
    // eslint-disable-next-line
  }, [currentSlide]);

  const sliderRef = useRef(null);
  const cardRefs = AvailableJobs.map(() => React.createRef());
  const handleMouseMove = (event, index) => {
    const rect = cardRefs[index].current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    cardRefs[index].current.style.setProperty("--left", `${x}px`);
    cardRefs[index].current.style.setProperty("--top", `${y}px`);
  };
  return (
    <div className="jobContainer">
      <div className="JobLister">
        <div className="JobSectionHeading">
          Recent <span style={{ color: "#DBFF00" }}>Openings</span>
        </div>
        <div className="scrollBtns">
          <img
            src={prev}
            alt="left-arrow"
            onClick={() => sliderRef.current.slickPrev()}
            className={`prevjob jobbtn ${isPrevDisabled ? "" : "jobhover"}`}
            style={{ opacity: isPrevDisabled ? 0.2 : 0.6, cursor: isPrevDisabled ? "auto" : "pointer"}}
          />
          <img
            src={next}
            alt="right-arrow"
            onClick={() => {
              if (!isNextDisabled) {
                sliderRef.current.slickNext();
              }
            }}
            className={`jobbtn ${isNextDisabled ? "" : "jobhover"}`}
            style={{ opacity: isNextDisabled ? 0.2 : 0.6, cursor: isNextDisabled ? "auto" : "pointer"}}
          />
        </div>
      </div>
      <Slider {...settings} className="AllJobs" ref={sliderRef}>
        {AvailableJobs.map((job, index) => {
          return (
          <div className="jobslide-container" key={index}>
            <div
              className="EachJob"
              ref={cardRefs[index]}
              onClick={() => window.open(job.link, "_blank")}
              onMouseMove={(event) => handleMouseMove(event, index)}
            >
              <div className="EachJobHolder">
                <div className="JobTitle">{job.jobTitle}</div>
                <div className="JobLocContainer">
                  <img style={{ paddingRight: "5px" }} src={Loc} alt=""></img>
                  <span className="JobLoc">{job.location}</span>
                </div>
                <div className="JobSkillsContainer">
                  <span className="JobSkill">{job.skills}</span>
                </div>
                  <div className="JobSpecs" style={{ gap: "10px" }}>
                    <div className="JobTime">
                      <span>{job.exp}</span>
                    </div>
                    <div className="JobTime">
                      <span>{job.type}</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          )
        })}
        {/* </Slider> */}
      </Slider>
    </div>
  );
}

export default JobOpening;
