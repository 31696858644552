import React, { useState } from "react";
import "./Css/Navbar.css";
// import SqubixLogo from "./Assets/Icons/Squbix.svg";
import SqubixIcon from "./Assets/Icons/Logo.svg";
import HamburgerIcon from "./Assets/Icons/hamburger.png";
import CrossIcon from "./Assets/Icons/cross.png";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
const Navbar = ({ WhichTab }) => {
  const [navShow, setNavShow] = useState(false);

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location.pathname]);

  const handleHamburger = () => {
    setNavShow((prevState) => !prevState);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className={`Navbar ${navShow ? "add-bg" : "remove-bg"}`}
        initial={{ x: -100 }}
        animate={{ x: 0, transition: "ease-in" }}
        exit={{ x: -100 }}
      >
        <div className={"Navbar-d"}>
          <div
            className="Navbar-l"
            style={{ height: `${navShow ? "51px" : ""}` }}
          >
            <div className="Navbar-l-item">
              <Link to="/" onClick={() => setNavShow(false)}>
                <img onClick={scrollToTop} src={SqubixIcon} alt="squbix-icon" />
              </Link>
            </div>
            {/* {navShow && <div className="nav-text Navbar-l-item"><span >Welcome to SQUBIX</span></div>} */}
            <Link
              to="/"
              className="squbix-logo"
              onClick={() => setNavShow(false)}
            >
              <img
                onClick={scrollToTop}
                src={SqubixIcon}
                alt="squbix-logo"
              ></img>
            </Link>
            <div className="Navbar-l-item" onClick={handleHamburger}>
              {navShow ? (
                <img src={CrossIcon} alt="cross-icon" />
              ) : (
                <img src={HamburgerIcon} alt="hamburger-icon" />
              )}
            </div>
          </div>
          <div
            className={`${
              navShow ? "show-nav-link" : "hide-nav-link"
            } Navbar-r`}
          >
            <div>
              <Link
                to="/about"
                className={
                  WhichTab === "about"
                    ? "Navbar-r-item-active"
                    : "Navbar-r-item"
                }
                onClick={() => setNavShow(false)}
              >
                About
              </Link>
            </div>
            <div>
              <Link
                to="/products"
                className={
                  WhichTab === "products"
                    ? "Navbar-r-item-active"
                    : "Navbar-r-item"
                }
                onClick={() => setNavShow(false)}
              >
                Products
              </Link>
            </div>
            <div>
              <Link
                to="/training"
                className={
                  WhichTab === "training"
                    ? "Navbar-r-item-active"
                    : "Navbar-r-item"
                }
                onClick={() => setNavShow(false)}
              >
                Training
              </Link>
            </div>
            <div>
              <Link
                to="/blog"
                className={
                  WhichTab === "blog" ? "Navbar-r-item-active" : "Navbar-r-item"
                }
                onClick={() => setNavShow(false)}
              >
                Blogs
              </Link>
            </div>

            <div>
              <Link
                to="/contactus"
                className={
                  WhichTab === "contactus"
                    ? "Navbar-r-item-active"
                    : "Navbar-r-item"
                }
                onClick={() => setNavShow(false)}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Navbar;
