import React, { useEffect } from "react";
import { useState } from "react";
import "../Blog/CSS/blog.css";
import Footer from "../Footer/Footer";
import Blogcard from "./Blogcard";
// import { BlogData } from "./Assets/Data/BlogData";
import { blogjson } from "./Assets/Data/blogsJSON";
import error from "./Assets/Video/404.gif";

const Blog = ({ setNav }) => {
  const corpus = Object.values(blogjson);
  const [activeSection, setActiveSection] = useState("All");
  const [data, setData] = useState(corpus);

  useEffect(() => {
    setNav("blog");
  });

  useEffect(() => {
    const tempData = corpus.filter((e) => {
      if (activeSection === "All") return true;
      return e.technology.toLowerCase() === activeSection.toLowerCase();
    });
    setData(tempData);
    // eslint-disable-next-line
  }, [activeSection]);

  return (
    <div>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <div className="blog_page">
          <div className="Service_Header">Our insightful blog</div>
          <div className="BlogSelected">
            <div
              onClick={() => setActiveSection("All")}
              className={
                activeSection === "All"
                  ? "custom-button-selected"
                  : "custom-button"
              }
            >
              All
            </div>
            <div
              onClick={() => setActiveSection("blockchain")}
              className={
                activeSection === "blockchain"
                  ? "custom-button-selected"
                  : "custom-button"
              }
            >
              Blockchain
            </div>

            <div
              onClick={() => setActiveSection("AI")}
              className={
                activeSection === "AI"
                  ? "custom-button-selected"
                  : "custom-button"
              }
            >
              AI
            </div>
            <div
              onClick={() => setActiveSection("ML")}
              className={
                activeSection === "ML"
                  ? "custom-button-selected"
                  : "custom-button"
              }
            >
              ML
            </div>
            <div
              onClick={() => setActiveSection("Technology")}
              className={
                activeSection === "Technology"
                  ? "custom-button-selected"
                  : "custom-button"
              }
            >
              Technology
            </div>
            <div
              onClick={() => setActiveSection("webDev")}
              className={
                activeSection === "webDev"
                  ? "custom-button-selected"
                  : "custom-button"
              }
            >
              Web Development
            </div>
          </div>

          <div className="card_container">
            {data.length === 0 ? (
              <div className="noblog">
                <img src={error} alt="Error" style={{}} />
                No data found matching your criteria. Please try again later or
                explore other categories.
              </div>
            ) : (
              data?.map((e, index) => (
                <div className="AllCard" key={index}>
                  <Blogcard
                    id={e?.id}
                    img={e?.img}
                    headlines={e?.heading}
                    technology={e?.technology}
                    date={e?.date}
                    nav={e?.key}
                  />
                </div>
              ))
            )}
          </div>
        </div>
        <div className="blog-bg-1"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
