import React, { useEffect } from "react";
import "../Css/medbot.css";
import mainbg from "../Assets/IMG/medbot/mainbg.png";
import ai from "../Assets/IMG/medbot/ai.png";
import medication from "../Assets/IMG/medbot/medication.png";
import ttsbg from "../Assets/IMG/medbot/ttsbg.png";
import multiling from "../Assets/IMG/medbot/multiling.png";
import chat from "../Assets/IMG/medbot/chat.png";
import search from "../Assets/IMG/medbot/search.png";
import nurse from "../Assets/IMG/medbot/nurse.png";
import nurse2 from '../Assets/IMG/medbot/nurse2.png'
import secure2 from '../Assets/IMG/medbot/secure2.png'
import medbot1 from '../Assets/IMG/medbot-1.png'
import medbot2 from '../Assets/IMG/medbot-2.png'
import Aos from "aos";
import "aos/dist/aos.css";

export const Medbot = ({ setNav }) => {
  useEffect(() => {
    setNav("products");
    Aos.init({
      disable: window.innerWidth < 700,
    });
  });
  return (
    <div
      className="medbot-container"

    //  style={{backgroundColor:"#0B0B0B"}}
    >
      <div
        style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "1250px", marginBottom:"20px" }}
      >
        <div data-aos="fade-up" className="medbot-title text-center">
          Medbot
        </div>
        <div className="medbot-subtitle text-center">
          Your<span className="text-white medbot-paragraph"> AI </span>Assistant
          for Healthcare
        </div>
        <div data-aos="fade-up">
          <img src={mainbg} width={"100%"} alt="mochup " />
        </div>

        {/* 1st section */}
        <div className="mobile">
          <div className="ai-title mobile">
            Interact with a powerful <span className="text-white">AI</span> <span className="text-white">trained</span> on medical knowledge
          </div>
        </div>
        <div className="dev">
          <section
            style={{
              paddingTop: "180px",
            }}
          >
            <div className="first-section">
              <div data-aos="fade-up" className="first-left">
                <img src={ai} alt="" className="first-img" />
              </div>
              <div className="first-right d-flex justify-content-center align-items-center ">
                <div data-aos="fade-left" className="ai-title">
                  Interact with a powerful
                  <span className="text-white"> AI trained</span> on medical
                  knowledge.
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="mobile">
          <div className="search-container">
            <img src={search} alt="" width={"100%"} />
          </div>
        </div>
        <div className="mobile">
          <div className="tts-bg">
            <img src={ttsbg} alt="" width={"100%"} />
          </div>
        </div>

        {/* 2nd section */}
        <div className="mobile">
          <div className="stt-title">Speech-to-Text Transcription</div>
          <div className="stt-subtitle">
            Speak your questions in your language & receive transcribed text for
            clarity & convenience.
          </div>
          <div style={{ paddingTop: "57px",paddingBottom:"120px",paddingInline:"24px" }}>
            <img src={medbot2} width={"100%"} alt="" />
          </div>
          <div className="export-title">Get Expert Replies</div>
          <div>
            <img src={ai} width={"100%"} alt="" className="ai-image" />
          </div>
          <div>
            <img src={nurse} width={"100%"} alt="" className="nurse-image" />
          </div>
          <div>
            <div className="stt-title">Multispecialty Info</div>
            <div className="stt-subtitle">
              Access a vast library of <span className="text-white">medical information</span> across various
              specialties
            </div>
          </div>
          <div>
            <img src={medbot1} width={"100%"} alt="" className="ai-image" />
          </div>
          <div>
            <div className="stt-title">Archive Chats</div>
            <div className="stt-subtitle">
              Chat history for reference & review of past interactions
            </div>
          </div>
          <div>
            <img src={chat} width={"100%"} alt="" className="ai-image" />
          </div>
          <div>
            <div className="stt-title">Secure Authentication</div>
            <div className="stt-subtitle">
              Safeguard your information with secure login protocols
            </div>
          </div>
          <div>
            <img src={secure2} width={"100%"} alt="" className="ai-image" />
          </div>
          <div data-aos="fade-left" >
                <img src={nurse2} alt="" className="nurse-image" width={"100%"} />
              </div>
        </div>
        <div className="dev">
          <section
          // style={{
          //   paddingTop: "200px",
          // }}
          >
            <div className="d-flex justify-content-between second-section">
              <div className="d-flex justify-content-center align-items-center ">
                <div data-aos="fade-up" className="info-title">
                  Access a vast library of{" "}
                  <span className="text-white">medical information</span> across
                  various specialties.
                </div>
              </div>
              <div data-aos="fade-left" className="second-right">
                <img src={medication} alt="" className="second-img" />
              </div>
             
            </div>
          </section>
        </div>

        <div className="dev">
          {/* 3rd section */}
          <section>
            <div data-aos="fade-up" className="text-center tts-title">
              Speech-to-Text <span className="text-white">Transcription</span>{" "}
            </div>
            <div>
              <div className="d-flex justify-content-between  third-section">
                <div data-aos="fade-right" className="third-left">
                  <img src={ttsbg} alt="" className="tts-img" />
                </div>
                <div className="d-flex align-items-center tts-subtitle">
                  <div data-aos="fade-left">
                    <span className="text-white">Speak</span> your questions
                    naturally and receive transcribed text for{" "}
                    <span className="text-white">clarity</span> and convenience.
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* multiling section */}
          <section
            style={{
              paddingTop: "200px",
            }}
          >
            <div className="d-flex justify-content-between multi-section">
              <div className="d-flex justify-content-center align-items-center multi-left ">
                <div>
                  <div data-aos="fade-up" className="mul-title">
                    <span className="mul-span">Multi-lingual</span> Support
                  </div>
                  <div data-aos="fade-up" className="mul-subtitle">
                    Communicate with Medbot in{" "}
                    <span className="text-white">multiple languages</span> for a
                    more inclusive experience.
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" className="multi-right">
                <img src={multiling} alt="" />
              </div>
            </div>
          </section>
          {/* chat-history */}
          <section
            style={{
              paddingTop: "180px",
            }}
          >
            <div className="d-flex justify-content-between chat-section">
              <div data-aos="fade-up" className="chat-left">
                <img src={chat} alt="" className="chat-img" />
              </div>
              <div className="d-flex justify-content-center align-items-center ">
                <div data-aos="fade-left" className="ai-title">
                  <span className="text-white">Chat</span> history for reference
                  and review of{" "}
                  <span className="text-white">past interactions</span>.
                </div>
              </div>
            </div>
          </section>

          {/* footersection */}
          <section>
            <div data-aos="fade-up" className="text-center tts-title">
              Secure <span className="text-white">Authentication</span>{" "}
            </div>
            <div className="d-flex justify-content-between last-section">
              <div className="d-flex justify-content-center align-items-center ">
                <div>
                  <div data-aos="fade-right" className="secure-title">
                    <span className="text-white">Safeguard</span> your
                    information with <span className="text-white">secure</span>{" "}
                    login protocols
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" className="last-right">
                <img src={multiling} alt="" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
