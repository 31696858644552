import React, { useEffect } from "react";

//components import

// css import
import "../Css/Mediclub.css";

//icon import
import { BiSolidBellRing } from "react-icons/bi";

// images import
import heroimg from "../Assets/IMG/mediclub/herosection.png";
import mediclubLogo from "../Assets/IMG/mediclub/mediclub-logo.png";
import mockup1 from "../Assets/IMG/mediclub/mockup1.png";
import mockup2 from "../Assets/IMG/mediclub/mockup2.png";
import mockup3 from "../Assets/IMG/mediclub/mockup3.png";
import mockup4 from "../Assets/IMG/mediclub/mockup4.png";
import mockup5 from "../Assets/IMG/mediclub/mockup5.png";
import mockup6 from "../Assets/IMG/mediclub/mockup6.png";
import mockup7 from "../Assets/IMG/mediclub/mockup7.png";
import mockup8 from "../Assets/IMG/mediclub/mockup8.png";
import mockup9 from "../Assets/IMG/mediclub/mockup9.png";
import randomimg1 from "../Assets/IMG/mediclub/randomimg1.png";
import randomimg2 from "../Assets/IMG/mediclub/randomimg2.png";
import doctors from "../Assets/IMG/mediclub/doctorss.png";
import medilogo from "../Assets/IMG/mediclub/medilogo.png";
import girl from "../Assets/IMG/mediclub/girl-doctor.png";
import chatss from "../Assets/IMG/mediclub/chat-ss.png";
import gd from "../Assets/IMG/mediclub/gd.png";
import walletscreen from "../Assets/IMG/mediclub/wallet-screen.png";
import twomockup from "../Assets/IMG/mediclub/two-mockup.png";
import doctorphone from "../Assets/IMG/mediclub/doctorphone.png";

export const Mediclub = ({ setNav }) => {
  useEffect(() => {
    setNav("products");
  });
  return (
    <div
      className="background-color-mediclub"
      // style={{
      //   background:
      //     "linear-gradient(180deg, rgba(62, 173, 153, 0.3) 0%, rgba(26, 71, 63, 0.3) 100%)",
      // }}
    >
      <div className="mediclub-container py-5">
        <div className="herosection">
          <img src={heroimg} alt="heroimg" className="mediclub-heroimg dev" />
          <img
            src={doctors}
            alt="heroimg"
            className="mediclub-heroimg mobile"
          />
          <div className="mediclub-logo dev">
            <img src={mediclubLogo} alt="mediclubLogo" className="logo-img" />
          </div>
          <div className="mediclub-mobile-logo mobile">
            <img
              src={medilogo}
              alt="mediclubLogo"
              width={"100%"}
              className="logo-img"
            />
          </div>
        </div>

        <div className="mediclub-mockup-text-container">
          <div className="mediclub-mockup">
            <img src={mockup1} alt="mockup1" />
          </div>
          <div className="mediclub-text ">
            <div className="mediclub-heading1 ">
              <span className="span-color-mediclub">
                Connect.Collaborate.Care.
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className=" text-center exclusive-title mobile">
            Exclusive <span className="span-color-mediclub">social media</span>{" "}
            platform for <span className="span-color-mediclub">doctors</span>{" "}
            only
          </div>
        </div>

        <div className="mediclub-mockup-text-container">
          <div className="mediclub-text dev">
            <div className="mediclub-heading1 dev">
              Exclusive <span>social media</span> platform for{" "}
              <span>doctors</span> only.
            </div>
          </div>
          <div className="mediclub-mockup">
            <img src={mockup2} alt="mockup2" className="mockup-two" />
          </div>
        </div>

        <div className="mobile girl-container">
          <img src={girl} width={"100%"} alt="" />
        </div>
        <div className="mediclub-mockup-text-container">
          <div className="mediclub-mockup">
            <img src={mockup3} alt="mockup3" className="dev" />
            <img src={chatss} alt="mockup3" className="mobile" />
          </div>
          <div className="mediclub-text dev">
            <div className="mediclub-heading1 dev">
              <span>Connect</span> and <span>chat</span> with fellow doctors
              across specialties.
            </div>
          </div>
          <div className="mediclub-text mobile">
            <div className="connect-title">
              <span className="span-color-mediclub">Connect</span> and{" "}
              <span className="span-color-mediclub">chat</span> with fellow
              doctors across specialties.
            </div>
          </div>
        </div>
        <div>
          <div className="mobile specific-cotor-title">
            <span className="span-color-mediclub">Find specific doctors</span>{" "}
            according to location
          </div>
        </div>

        <div className="mediclub-mockup-text-container">
          <div className="mediclub-text">
            <div className="mediclub-heading1 dev">
              Discuss <span>medical cases</span>, share <span>knowledge</span>,
              and collaborate on patient care.
            </div>
          </div>
          <div className="mediclub-mockup">
            <img src={mockup4} alt="mockup4" className="mockup-two dev" />{" "}
            <img src={mockup5} alt="mockup4" className="mockup-two mobile" />
          </div>
        </div>
        <div className="mobile gd-container">
          <img src={gd} alt="" width={"100%"} />
        </div>

        <div
          className="mediclub-heading1 text-center dev"
          style={{ paddingTop: "150px" }}
        >
          <span className="nearby-doc">Find Nearby Doctors</span>
        </div>
        <div className="mobile notify-title ">
          Get notified about relevant{" "}
          <span className="span-color-mediclub">
            medical conferences, workshops
          </span>{" "}
          & <span className="span-color-mediclub">CME events</span>
        </div>
        <div className="dev">
          <div
            className="mediclub-mockup-text-container"
            style={{ position: "relative" }}
          >
            <div className="mediclub-text">
              <div
                className="mediclub-heading2 left-side"
                style={{
                  position: "absolute",
                  bottom: "100px",
                  // left: "20px",
                  left: "0px",
                  width: "30%",
                }}
              >
                See profiles of doctors with their <span>qualifications</span>{" "}
                and <span>experience</span>
              </div>
            </div>
            <div className="mediclub-mockup">
              <img src={mockup5} alt="mockup5" />
            </div>
            <div className="mediclub-text right-side">
              <div
                className="mediclub-heading2"
                style={{
                  position: "absolute",
                  top: "100px",
                  right: "20px",
                  width: "30%",
                }}
              >
                Advanced <span>search filters</span> to locate doctors by{" "}
                <span>specialization</span>, location, and{" "}
                <span>availability.</span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mediclub-full-img-text dev">
                    <img src={randomimg1} alt="randomimg1" />
                    <div
                        className="mediclub-text"
                        style={{
                            position: "absolute",
                            top: "20%",
                            left: 0,
                            right: 0,
                            margin: "auto",
                        }}
                    >
                        <div className="mediclub-heading1">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.
                        </div>
                    </div>
                </div> */}

        <div className="mediclub-mockup-text-container">
          <div className="mediclub-mockup">
            <img src={mockup6} alt="mockup6" />
          </div>
          <div className="mediclub-text dev">
            <div className="mediclub-heading1 dev">
              Get notified
              <span>
                <BiSolidBellRing />
              </span>
              about relevant <span>medical conferences, workshops,</span> and{" "}
              <span>CME events.</span>
            </div>
          </div>
        </div>

        <div className="mobile">
          <div className="contribute-title">
            <span className="span-color-mediclub">Contribute to a cause</span>{" "}
            you care about directly from the app
          </div>
        </div>

        <div className="mediclub-mockup-text-container ">
          <div className="dev">
            {/* <div className="mediclub-text">
                            <div className="mediclub-heading1">
                                <span>In-App Wallet</span>
                            </div>
                            <div className="mediclub-heading2">
                                Contribute to a cause you care about directly
                                from the app.
                            </div>
                        </div> */}
          </div>
          {/* <div className="mediclub-mockup dev">
                        <img src={mockup7} alt="mockup7" />
                    </div> */}
          <div className="mediclub-mockup mobile">
            <img src={twomockup} alt="mockup7" width={"100%"} />
          </div>
          <div className="mobile">
            <img src={walletscreen} alt="mockup7" />
            <div className="wallet-title ">
              <span className="span-color-mediclub">Donate funds</span> securely
              to various{" "}
              <span className="span-color-mediclub">medical relief</span> funds
              and awareness campaigns.
            </div>
          </div>
        </div>
        <div className="dev">
          <div className="mediclub-mockup-text-container"style={{paddingBottom:"30vh"}}>
            <div className="mediclub-mockup dev">
              <img src={mockup8} alt="mockup8" />
              <div className="mediclub-mockup-top">
                <img src={mockup9} alt="mockup9" />
              </div>
            </div>
            <div className="mediclub-text">
              <div className="mediclub-heading1 dev">
                <span>Donate funds</span> securely to various{" "}
                <span>medical relief</span> funds and awareness campaigns.
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mediclub-full-img-text dev">
                    <img src={doctorphone} alt="randomimg2" width={"100%"} />
                    
                </div> */}
      </div>
      <div className="mobile">
        <div className="help-title">
          Mediclub helps in{" "}
          <span className="span-color-mediclub">building the community</span>
        </div>
        <div className="phone-section">
          <img src={doctorphone} alt="" width={"100%"} />
        </div>
      </div>
    </div>
  );
};
