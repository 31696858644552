import React from "react";
import "../Css/TeamGallery.css";

const TeamGallery = () => {
  const carImages = [
    {
      id: 1,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ8.jpg?updatedAt=1710480731606",
    },
    {
      id: 2,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ9.jpg?updatedAt=1710480731516",
    },
    {
      id: 3,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ7.jpg?updatedAt=1710480731598",
    },
    {
      id: 4,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ10.jpg?updatedAt=1710480731507",
    },
    {
      id: 5,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/ft_img_2_720.jpg?updatedAt=1713269232612",
    },
    {
      id: 6,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ11.jpg?updatedAt=1710480731542",
    },
    {
      id: 7,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/ft_img_720.jpg?updatedAt=1713269232627",
    },
    {
      id: 8,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/ft_img_3_720.jpg?updatedAt=1713269232652",
    },
    {
      id: 9,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ6.jpg?updatedAt=1710480734737",
    },
    {
      id: 10,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ2.jpg?updatedAt=1710480734343",
    },
    {
      id: 11,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ5.jpeg?updatedAt=1710480734354",
    },
    {
      id: 12,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ3.jpeg?updatedAt=1710480734258",
    },
    {
      id: 13,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ14.jpg?updatedAt=1710480731672",
    },
    {
      id: 14,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ12.jpg?updatedAt=1710480731664",
    },
    {
      id: 15,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/Squ13.jpg?updatedAt=1710480731668",
    },
    {
      id: 16,
      img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/TeamGallery/ft_img_4_720.jpg?updatedAt=1713269232610",
    },
  ];

  const allImages = [...carImages, ...carImages];

  return (
    <div className="carousel-page">
      <div className="car-image-wrapper">
        {allImages.map((image, index) => (
          <div key={index} className="car-image-container-wrapper">
            <div className="car-image-container">
              <img
                src={image.img}
                alt={`team-gallery-${index}`}
                className="car-image"
                loading="lazy"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamGallery;
