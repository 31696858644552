import React, { useEffect, useRef, useState } from "react";

import "./Css/CourseContent.css";
import Footer from "../Footer/Footer";
import { useParams } from "react-router";
import { FaChevronDown } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import corpus from "./Assets/Data/Courses.json";

const CourseContent = ({ setNav }) => {
  const { tid } = useParams();
  const data = corpus[tid];

  const [openIcons, setOpenIcons] = useState({
    basic: true,
    intermediate: true,
    advance: true,
  });
  const [openCourseDetails, setOpenCourseDetails] = useState({
    basic: false,
    intermediate: false,
    advance: false,
  });

  const heading1Ref = useRef(null);
  const heading2Ref = useRef(null);
  const heading3Ref = useRef(null);
  const heading4Ref = useRef(null);
  const heading5Ref = useRef(null);

  useEffect(() => {
    setNav("training");
    // eslint-disable-next-line
  }, []);

  const toggleCourseDetails = (level) => {
    setOpenCourseDetails((prev) => ({
      ...prev,
      [level]: !prev[level],
    }));
    setOpenIcons((prev) => ({
      ...prev,
      [level]: !prev[level],
    }));
  };

  const scrollToHeading = (ref) => {
    window.scroll({
      top: ref.current.offsetTop - 150,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="mainContainer">
        {/* Course Banner */}
        <div className="courseImgContainer">
          <img src={data?.img} alt="react" />
        </div>
        <div className="courseContainer">
          {/* Course Header */}
          <div className="courseHeading">
            <div className="courseTitle">{data?.name}</div>
            {/* Apply Now Button */}
            <div className="courseApply">
              <a
                href="https://forms.gle/V6vTov4K79E1NCmA7"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "#000" }}
              >
                Apply Now
              </a>
            </div>
          </div>
          <div className="NavDescContainer">
            {/* Course Navigation */}
            <div className="course-nav">
              <div
                className="course-nav-item"
                onClick={() => {
                  scrollToHeading(heading1Ref);
                }}
              >
                About this Course
              </div>
              <div
                className="course-nav-item"
                onClick={() => {
                  scrollToHeading(heading2Ref);
                }}
              >
                Why this Course?
              </div>
              <div
                className="course-nav-item"
                onClick={() => {
                  scrollToHeading(heading3Ref);
                }}
              >
                What will you learn
              </div>
              <div
                className="course-nav-item"
                onClick={() => {
                  scrollToHeading(heading4Ref);
                }}
              >
                Course Contents
              </div>
              <div
                className="course-nav-item"
                onClick={() => {
                  scrollToHeading(heading5Ref);
                }}
              >
                About this Professional Certificate
              </div>
            </div>

            {/* About this course */}
            <div className="courseDescription">
              <div className="courseDescHeading" ref={heading1Ref}>
                About this Course
              </div>
              <div className="courseDesc">{data.about}</div>

              {/* Why this course */}
              <div className="courseDescHeading" ref={heading2Ref}>
                Why this Course?
              </div>
              <div className="courseDesc">
                {data.opportunities.map((item, index) => {
                  return (
                    <div className="courseDescItem" key={index}>
                      • &nbsp;{item}
                    </div>
                  );
                })}
              </div>

              {/* What will you learn */}
              <div className="courseDescHeading" ref={heading3Ref}>
                What will you learn?
              </div>
              <div className="courseDesc">
                {data.learning.map((item, index) => {
                  return (
                    <div className="courseDescItem" key={index}>
                      • &nbsp;{item}
                    </div>
                  );
                })}
              </div>

              {/* Course Contents */}
              <div className="courseDescHeading" ref={heading4Ref}>
                Course Contents:
              </div>

              {/* All (Basic, Intermediate and Advanced) Level Course Details */}

              {["basic", "intermediate", "advance"].map((level, idx) => (
                <div
                  key={idx}
                  class="card text-white bg-dark mb-3 cardStyle"
                  style={{ borderRadius: "20px" }}
                >
                  <div class="card-body bodyCard">
                    {openIcons[level] ? (
                      <div
                        className="cardBody"
                        onClick={() => toggleCourseDetails(level)}
                      >
                        <div class="card-title titleText">{`${
                          level.charAt(0).toUpperCase() + level.slice(1)
                        } Level`}</div>
                        <FaChevronDown className="right-arrow" />
                      </div>
                    ) : (
                      <div
                        className="cardBody"
                        onClick={() => toggleCourseDetails(level)}
                      >
                        <div class="card-title titleText">{`${
                          level.charAt(0).toUpperCase() + level.slice(1)
                        } Level`}</div>
                        <RxCross2 className="cross-icon" />
                      </div>
                    )}

                    {openCourseDetails[level] && (
                      <p class="card-text">
                        {data[level]?.map((item, index) => {
                          return (
                            <div className="content-body" key={index}>
                              {item?.heading && (
                                <div className="content-heading">
                                  MOD {index + 1 + ": " + item.heading}
                                </div>
                              )}
                              {item.content.map((content, idx) => {
                                return (
                                  <div className="content-item" key={idx}>
                                    • {content}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </p>
                    )}
                  </div>
                </div>
              ))}

              {/* About this Professional Certificate */}
              <div className="courseDescHeading" ref={heading5Ref}>
                About this Professional Certificate
              </div>
              <div className="courseDesc">{data.certificateInfo}</div>
              <div className="squbix-certificate">
                <img src={data.certificateImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CourseContent;
