import React from "react";

const AddCertificate = ({ formData, setFormData }) => {
  return (
    <form>
      <div className="form-group mb-2">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={formData?.name}
          required
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
      </div>
      <div className="form-group mb-2">
        <label htmlFor="branch">Branch</label>
        <input
          type="text"
          className="form-control"
          required
          id="branch"
          value={formData?.branch}
          onChange={(e) => setFormData({ ...formData, branch: e.target.value })}
        />
      </div>
      <div className="form-group mb-2">
        <label htmlFor="branch">Designation</label>
        <input
          type="text"
          required
          className="form-control"
          id="branch"
          value={formData?.designation}
          onChange={(e) =>
            setFormData({ ...formData, designation: e.target.value })
          }
        />
      </div>
      <div className="form-group mb-2">
        <label htmlFor="college">College</label>
        <input
          type="text"
          required
          className="form-control"
          id="college"
          value={formData.college}
          onChange={(e) =>
            setFormData({ ...formData, college: e.target.value })
          }
        />
      </div>
      {/* <div className="form-group mb-2">
            <label htmlFor="role">Role</label>
            <input
              type="text"
              className="form-control"
              id="role"
              value={formData.role}
              onChange={(e) =>
                setFormData({ ...formData, role: e.target.value })
              }
            />
          </div> */}
      <div className="form-group row mb-2">
        <div className="col">
          <label htmlFor="startDate">Start Date</label>
          <input
            type="date"
            required
            className="form-control"
            id="startDate"
            value={formData?.startDate}
            onChange={(e) =>
              setFormData({ ...formData, startDate: e.target.value })
            }
          />
        </div>
        <div className="col">
          <label htmlFor="endDate">End Date</label>
          <input
            type="date"
            required
            className="form-control"
            id="endDate"
            value={formData?.endDate}
            onChange={(e) =>
              setFormData({ ...formData, endDate: e.target.value })
            }
          />
        </div>
      </div>

      <div className="form-group">
        <label>Issue Date</label>
        <input
          type="date"
          required
          className="form-control"
          value={formData?.issueDate}
          onChange={(e) =>
            setFormData({ ...formData, issueDate: e.target.value })
          }
        />
      </div>
    </form>
  );
};

export default AddCertificate;
